/* tslint:disable */
/* eslint-disable */
/**
 * Phenix Auth Documentation
 * Swagger
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountUsersResponse } from '../models';
// @ts-ignore
import { DefaultResponse } from '../models';
// @ts-ignore
import { NotFoundException } from '../models';
// @ts-ignore
import { PostUserAdminUpdateUserParams } from '../models';
// @ts-ignore
import { PostUserUpdateUserParams } from '../models';
// @ts-ignore
import { PutUserUpdateUserParams } from '../models';
// @ts-ignore
import { UserUserDetails } from '../models';
// @ts-ignore
import { UserUserEmailNotification } from '../models';
// @ts-ignore
import { UsersResponse } from '../models';
// @ts-ignore
import { ValidationException } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/user/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} salesforceId 
         * @param {string} [page] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUsers: async (salesforceId: string, page?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'salesforceId' is not null or undefined
            assertParamExists('getAccountUsers', 'salesforceId', salesforceId)
            const localVarPath = `/account/{salesforceId}/users`
                .replace(`{${"salesforceId"}}`, encodeURIComponent(String(salesforceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEmailNotification: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/email-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [page] 
         * @param {string} [search] 
         * @param {string} [role] 
         * @param {Array<any>} [accountIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (page?: string, search?: string, role?: string, accountIds?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (accountIds) {
                localVarQueryParameter['accountIds[]'] = accountIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostUserUpdateUserParams} [postUserUpdateUserParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser: async (postUserUpdateUserParams?: PostUserUpdateUserParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postUserUpdateUserParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PostUserAdminUpdateUserParams} [postUserAdminUpdateUserParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserAdmin: async (id: string, postUserAdminUpdateUserParams?: PostUserAdminUpdateUserParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postUserAdmin', 'id', id)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postUserAdminUpdateUserParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutUserUpdateUserParams} [putUserUpdateUserParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser: async (putUserUpdateUserParams?: PutUserUpdateUserParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putUserUpdateUserParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} salesforceId 
         * @param {string} [page] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountUsers(salesforceId: string, page?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountUsers(salesforceId, page, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserEmailNotification(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserEmailNotification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserEmailNotification(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [page] 
         * @param {string} [search] 
         * @param {string} [role] 
         * @param {Array<any>} [accountIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(page?: string, search?: string, role?: string, accountIds?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(page, search, role, accountIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostUserUpdateUserParams} [postUserUpdateUserParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUser(postUserUpdateUserParams?: PostUserUpdateUserParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUser(postUserUpdateUserParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PostUserAdminUpdateUserParams} [postUserAdminUpdateUserParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserAdmin(id: string, postUserAdminUpdateUserParams?: PostUserAdminUpdateUserParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserAdmin(id, postUserAdminUpdateUserParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PutUserUpdateUserParams} [putUserUpdateUserParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUser(putUserUpdateUserParams?: PutUserUpdateUserParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUser(putUserUpdateUserParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<DefaultResponse> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} salesforceId 
         * @param {string} [page] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUsers(salesforceId: string, page?: string, search?: string, options?: any): AxiosPromise<AccountUsersResponse> {
            return localVarFp.getAccountUsers(salesforceId, page, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<UserUserDetails> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEmailNotification(options?: any): AxiosPromise<UserUserEmailNotification> {
            return localVarFp.getUserEmailNotification(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [page] 
         * @param {string} [search] 
         * @param {string} [role] 
         * @param {Array<any>} [accountIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(page?: string, search?: string, role?: string, accountIds?: Array<any>, options?: any): AxiosPromise<UsersResponse> {
            return localVarFp.getUsers(page, search, role, accountIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostUserUpdateUserParams} [postUserUpdateUserParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUser(postUserUpdateUserParams?: PostUserUpdateUserParams, options?: any): AxiosPromise<DefaultResponse> {
            return localVarFp.postUser(postUserUpdateUserParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PostUserAdminUpdateUserParams} [postUserAdminUpdateUserParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserAdmin(id: string, postUserAdminUpdateUserParams?: PostUserAdminUpdateUserParams, options?: any): AxiosPromise<DefaultResponse> {
            return localVarFp.postUserAdmin(id, postUserAdminUpdateUserParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PutUserUpdateUserParams} [putUserUpdateUserParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser(putUserUpdateUserParams?: PutUserUpdateUserParams, options?: any): AxiosPromise<DefaultResponse> {
            return localVarFp.putUser(putUserUpdateUserParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} salesforceId 
     * @param {string} [page] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAccountUsers(salesforceId: string, page?: string, search?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAccountUsers(salesforceId, page, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserEmailNotification(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserEmailNotification(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [page] 
     * @param {string} [search] 
     * @param {string} [role] 
     * @param {Array<any>} [accountIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(page?: string, search?: string, role?: string, accountIds?: Array<any>, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsers(page, search, role, accountIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostUserUpdateUserParams} [postUserUpdateUserParams] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUser(postUserUpdateUserParams?: PostUserUpdateUserParams, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postUser(postUserUpdateUserParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PostUserAdminUpdateUserParams} [postUserAdminUpdateUserParams] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUserAdmin(id: string, postUserAdminUpdateUserParams?: PostUserAdminUpdateUserParams, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postUserAdmin(id, postUserAdminUpdateUserParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PutUserUpdateUserParams} [putUserUpdateUserParams] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUser(putUserUpdateUserParams?: PutUserUpdateUserParams, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).putUser(putUserUpdateUserParams, options).then((request) => request(this.axios, this.basePath));
    }
}
