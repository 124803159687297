import React, { useCallback, useRef, useState } from 'react';
import {
    usePropertyErrors,
    InputWithLabel,
    Section,
    SelectInput,
    TextInput,
    SwitchInput,
    translate, TokenData, TokenDataPermissionsEnum, Link, Button,
} from '@wearephenix/phenix-front-core';
import styles from './UserFields.module.scss';
import { Grid, Typography } from '@mui/material';
import { AccountAccountDetails, UserUserDetails } from '../../../helpers/api';
import DeleteUserConfirmationPopup from '../popups/DeleteUserConfirmationPopup';
import AdditionalFields from '../../molecules/AdditionalFields';
import roles from '../../../helpers/constants/roles';
import AccountSearcher from '../../molecules/AccountSearcher';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

export interface UserFieldsProps {
    user: UserUserDetails | undefined
    onClickButton: (firstname: string, lastname: string, email: string, phone: string, role: string, accounts: number[], emailNotification: boolean, canSign: boolean, canSignDac: boolean, canAdminTech: boolean) => void
    buttonLabel: string
    linkLabel: string
    onClickLink: () => void
    type: 'create' | 'update'
}

const UserFields = ({ user, onClickButton, buttonLabel, linkLabel, onClickLink, type }: UserFieldsProps): JSX.Element => {
    const rolesData = [roles.USER, roles.ADMIN, roles.CARRIER];

    const [role, setRole] = useState(rolesData.find((roleData) => user?.roles?.includes(roleData.value))?.id ?? rolesData[0].id);
    const propertyErrors = usePropertyErrors();
    const tokenData = useSelector<RootState, TokenData | null>(state => state.token.token);

    const accountsLinked = useRef<AccountAccountDetails[]>(user?.accounts ?? []);
    const [lastname, setLastname] = useState(user?.lastname ?? '');
    const [firstname, setFirstname] = useState(user?.firstname ?? '');
    const [email, setEmail] = useState(user?.email ?? '');
    const [phone, setPhone] = useState(user?.phone_number ?? '');
    const [emailNotification, setEmailNotification] = useState(user?.email_notification ?? true);
    const [canSign, setCanSign] = useState(user?.signatory ?? false);
    const [canSignDac, setCanSignDac] = useState(user?.signatory_dac ?? true);
    const [canAdminTech, setCanAdminTech] = useState(user?.admin_tech ?? false);
    const [deleteUserPopup, setDeleteUserPopup] = useState(false);
    const isLoggedUserAdminTech = tokenData?.permissions?.includes(TokenDataPermissionsEnum.AdminTech) ?? false;
    const isAdminUser = role === roles.ADMIN.id;

    const onInputChange = useCallback((evt: any) => {
        const target = evt.target as HTMLInputElement;
        const { value, name } = target;
        const checked = target.checked;

        switch (name) {
            case 'lastname':
                setLastname(value);
                break;
            case 'firstname':
                setFirstname(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'roles':
                setRole(parseInt(value));
                break;
            case 'emailNotification':
                setEmailNotification(checked);
                break;
            case 'canSign':
                setCanSign(checked);
                break;
            case 'canSignDac':
                setCanSignDac(checked);
                break;
            case 'canAdminTech':
                setCanAdminTech(checked);
                break;
        }
    }, []);

    const onClickCreate = useCallback(() => {
        void (async () => {
            const selectedRole = rolesData.find((data) => data.id === role) ?? { value: 'user' };
            const accounts: number[] = [];

            accountsLinked.current.map((account) => {
                if (account.id !== undefined) {
                    accounts.push(account.id);
                }
                return account;
            });

            onClickButton(firstname, lastname, email, phone, selectedRole.value, accounts, emailNotification, canSign, canSignDac, canAdminTech);
        })();
    }, [firstname, lastname, email, phone, rolesData, role, emailNotification, canSign, canSignDac, canAdminTech]);

    return (
        <>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={6}
                >
                    <Section color={'black'} title={translate('user.details.main_info.section')}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <InputWithLabel
                                    forId={'roles'}
                                    label={translate('user.field.label.role')}
                                    required
                                >
                                    <SelectInput
                                        id={'roles'}
                                        name={'roles'}
                                        placeholder={translate('user.field.placeholder.role')}
                                        data={rolesData}
                                        onChange={onInputChange}
                                        fullWidth
                                        info={role === roles.CARRIER.id ? translate('user.field.info.role.carrier') : undefined}
                                        styleInfo={role === roles.CARRIER.id ? { fontWeight: 'bold' } : undefined}
                                        hasError={propertyErrors.includes('roles')}
                                        value={role}
                                        dataCy={'authUserDetailsRoleSelect'}
                                        disabled={type === 'update'}
                                    />
                                </InputWithLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <InputWithLabel
                                    forId={'firstname'}
                                    label={translate('user.field.label.firstname')}
                                    required
                                >
                                    <TextInput
                                        id={'firstname'}
                                        name={'firstname'}
                                        value={firstname}
                                        hasError={propertyErrors.includes('firstname')}
                                        placeholder={translate('user.field.placeholder.firstname')}
                                        onChange={onInputChange}
                                        dataCy={'authUserDetailsFirstnameInput'}
                                    />
                                </InputWithLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <InputWithLabel
                                    forId={'lastname'}
                                    label={translate('user.field.label.lastname')}
                                    required
                                >
                                    <TextInput
                                        id={'lastname'}
                                        name={'lastname'}
                                        value={lastname}
                                        hasError={propertyErrors.includes('lastname')}
                                        placeholder={translate('user.field.placeholder.lastname')}
                                        onChange={onInputChange}
                                        dataCy={'authUserDetailsLastnameInput'}
                                    />
                                </InputWithLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <InputWithLabel
                                    forId={'email'}
                                    label={translate('user.field.label.email')}
                                    required
                                >
                                    <TextInput
                                        id={'email'}
                                        name={'email'}
                                        value={email}
                                        hasError={propertyErrors.includes('email')}
                                        placeholder={translate('user.field.placeholder.email')}
                                        onChange={onInputChange}
                                        pattern={isAdminUser ? '.+(@wearephenix\\.com)$' : undefined}
                                        dataCy={'authUserDetailsEmailInput'}
                                    />
                                </InputWithLabel>
                            </Grid>
                            <AdditionalFields
                                role={role}
                                phone={phone}
                                onInputChange={onInputChange}
                                accountsLinked={accountsLinked}
                                emailNotification={emailNotification}
                            />
                        </Grid>
                    </Section>
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            { (role === roles.USER.id || (isAdminUser && isLoggedUserAdminTech)) &&
                            <Section color={'black'} title={translate('user.details.permissions.section')}>
                                {role === roles.USER.id
                                    ? <Grid container spacing={1}>
                                        <Grid item xs={12} className={styles.InputSwitchContainer}>
                                            <InputWithLabel
                                                forId={'canSign'}
                                                label={''}
                                            >
                                                <div className={styles.SwitchContainer}>
                                                    <Typography variant='body1' className={styles.TextSwitch}>{translate('switch.user.field.can_sign')}</Typography>
                                                    <SwitchInput
                                                        id={'canSign'}
                                                        name={'canSign'}
                                                        checked={canSign}
                                                        onChange={onInputChange}
                                                        dataCy={'authUserFieldsCanSignSwitch'}
                                                    />
                                                </div>
                                            </InputWithLabel>
                                        </Grid>
                                        <Grid item xs={12} className={styles.Divider}>
                                            <hr/>
                                        </Grid>
                                        <Grid item xs={12} className={styles.InputSwitchContainer}>
                                            <InputWithLabel
                                                forId={'canSignDac'}
                                                label={''}
                                            >
                                                <div className={styles.SwitchContainer}>
                                                    <Typography variant='body1' className={styles.TextSwitch}>{translate('switch.user.field.can_sign_dac')}</Typography>
                                                    <SwitchInput
                                                        id={'canSignDac'}
                                                        name={'canSignDac'}
                                                        checked={canSignDac}
                                                        onChange={onInputChange}
                                                        dataCy={'authUserFieldsCanSignDacSwitch'}
                                                    />
                                                </div>
                                            </InputWithLabel>
                                        </Grid>
                                    </Grid>

                                    : <div className={styles.InputSwitchContainer}>
                                        <InputWithLabel
                                            forId={'canAdminTech'}
                                            label={''}
                                        >
                                            <div className={styles.SwitchContainer}>
                                                <Typography variant='body1' className={styles.TextSwitch}>{translate('switch.user.field.can_admin_tech')}</Typography>
                                                <SwitchInput
                                                    id={'canAdminTech'}
                                                    name={'canAdminTech'}
                                                    checked={canAdminTech}
                                                    onChange={onInputChange}
                                                    dataCy={'authUserFieldsCanAdminTechSwitch'}
                                                />
                                            </div>
                                        </InputWithLabel>
                                    </div>
                                }
                            </Section>
                            }
                            {isAdminUser &&
                                <Section color={'black'} title={translate('user.details.notifications.section')}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <InputWithLabel
                                                forId={'emailNotification'}
                                                label={translate('label.user.field.email_notification')}
                                            >
                                                <div className={styles.SwitchContainer}>
                                                    <Typography variant='body1' className={styles.TextSwitch}>{translate('switch.user.field.email_notification')}</Typography>
                                                    <SwitchInput
                                                        id={'emailNotification'}
                                                        name={'emailNotification'}
                                                        checked={emailNotification}
                                                        onChange={onInputChange}
                                                        dataCy={'authUserFieldsEmailNotificationSwitch'}
                                                    />
                                                </div>
                                            </InputWithLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AccountSearcher id={'link'} accounts={accountsLinked.current} onChange={(values) => {
                                                accountsLinked.current = values;
                                            }} isAdmin={true} disabled={!emailNotification} />
                                        </Grid>
                                    </Grid>
                                </Section>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container flexDirection='row' justifyContent={'space-between'} style={{ marginTop: '2rem' }}>
                <Link
                    label={linkLabel}
                    onClick={() => setDeleteUserPopup(true)}
                    dataCy={'authUserFieldsDeleteLink'}/>
                <Button
                    label={buttonLabel}
                    color={'coral'}
                    onClick={onClickCreate}
                    dataCy={'authUserFieldsUpdateButton'}
                />
            </Grid>
            <DeleteUserConfirmationPopup open={deleteUserPopup} onCancel={() => setDeleteUserPopup(false)} onConfirm={onClickLink}/>
        </>
    );
};

export default UserFields;
