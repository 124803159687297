import React from 'react';
import { Outlet } from 'react-router-dom';
import Nav from '../../../organisms/Nav';
import styles from '../../../../App.module.scss';
import Header from '../../../organisms/Header';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { AlertSection, ErrorPage, TokenData, TokenError } from '@wearephenix/phenix-front-core';
import ErrorDefault from '../../../molecules/ErrorDefault';
import Error403 from '../../../molecules/Error403';

const Authenticated = (): JSX.Element | null => {
    const tokenData = useSelector<RootState, TokenData | null>(state => state.token.token);
    const tokenError = useSelector<RootState, TokenError | null>(state => state.token.tokenError);

    if (tokenData === null) {
        if (tokenError !== null) {
            if (tokenError.code === 403) {
                return <ErrorPage><Error403/></ErrorPage>;
            }

            if (tokenError.code === 401) {
                if (process.env.REACT_APP_URL_AUTH_LOGIN === undefined) {
                    return null;
                }
                window.location.replace(`${process.env.REACT_APP_URL_AUTH_LOGIN}?redirect=${window.location.href}`);
            } else {
                return <ErrorPage><ErrorDefault exception={tokenError.exception}/></ErrorPage>;
            }
        }
        return null;
    }

    if (!tokenData.roles.includes('admin')) {
        return <ErrorPage><Error403/></ErrorPage>;
    }

    return (<>
        <Nav/>
        <div className={styles.WithHeaderContainer}>
            <Header/>
            <AlertSection useMargin/>
            <Outlet/>
        </div>
    </>);
};

export default React.memo(Authenticated);
