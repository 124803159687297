import * as actionTypes from '../actionTypes';
import { UsersAction, UsersState } from '../types/users';

const initialState: UsersState = {
    users: [],
    pageCountUsers: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state: UsersState = initialState, action: UsersAction): UsersState => {
    switch (action.type) {
        case actionTypes.UPDATE_USERS_DATA:
            if (action.params.users === undefined || action.params.pageCount === undefined) {
                return state;
            }
            return {
                ...state,
                users: action.params.users,
                pageCountUsers: action.params.pageCount,
            };
    }
    return state;
};

export default reducer;
