import React, { Dispatch, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import ApiLoader from '../../ApiLoader';
import { retrieveToken } from '../../../../redux/actions/globalInfos';
import { useDispatch } from 'react-redux';

const LoadTokenData = (): JSX.Element | null => {
    const dispatch: Dispatch<any> = useDispatch();

    const executeApiLoader = useCallback(async () => {
        await dispatch(retrieveToken());
    }, []);

    return (
        <ApiLoader execute={executeApiLoader}>
            <Outlet/>
        </ApiLoader>
    );
};

export default React.memo(LoadTokenData);
