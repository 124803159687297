import { authenticationApi } from '../../helpers/api';
import { hideError, isLoginException, isServerException, loading, updateToken, showError } from '@wearephenix/phenix-front-core';

export function retrieveToken() {
    return async (dispatch: any) => {
        try {
            const { data } = await authenticationApi.getToken();
            dispatch(updateToken(data));
        } catch (e) {
            let errorCode = null;
            if (isServerException(e)) {
                errorCode = e.status;
            } else if (isLoginException(e)) {
                errorCode = e.code;
            }
            dispatch(updateToken(null, {
                code: errorCode,
                exception: e,
            }));
        }
    };
}

export function logout() {
    return async (dispatch: any) => {
        try {
            dispatch(hideError());
            dispatch(loading(true));
            await authenticationApi.postLogout();
            dispatch(updateToken(null, { code: 401, exception: new Error('Redirect logout') }));
        } catch (e) {
            dispatch(showError(e));
        } finally {
            dispatch(loading(false));
        }
    };
}
