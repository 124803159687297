import React, { useCallback } from 'react';
import { ApiLoader as ApiLoaderCore, ErrorPage } from '@wearephenix/phenix-front-core';
import ErrorDefault from '../../molecules/ErrorDefault';

export interface ApiLoaderProps {
    execute: () => Promise<void>
    screenError?: (e: any) => JSX.Element
    children: JSX.Element
    localLoading?: boolean
}

const ApiLoader = ({
    execute,
    screenError = undefined,
    children,
    localLoading = true,
}: ApiLoaderProps): JSX.Element | null => {
    if (screenError === undefined) {
        screenError = useCallback((e: any) => <ErrorPage>
            <ErrorDefault exception={e}/>
        </ErrorPage>, []);
    }

    return <ApiLoaderCore
        execute={execute}
        screenError={screenError}
        localLoading={localLoading}
    >
        {children}
    </ApiLoaderCore>;
};

export default React.memo(ApiLoader);
