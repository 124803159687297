import React, { useCallback } from 'react';
import { Error, translate, parseError } from '@wearephenix/phenix-front-core';
import { useNavigate } from 'react-router-dom';
import { routing } from '../../../helpers/constants/routing';

export interface ErrorDefaultProps {
    exception: any
}
const ErrorDefault = ({
    exception,
}: ErrorDefaultProps): JSX.Element => {
    const navigate = useNavigate();

    const errorText = parseError(exception);

    const onClickBack = useCallback(() => {
        navigate(routing.public.login);
    }, []);

    return <Error
        title={translate('error.generic.title')}
        description={Array.isArray(errorText) ? errorText.join('\n') : errorText}
        button={{
            label: translate('error.button.back'),
            onClick: onClickBack,
        }}
    />;
};

export default React.memo(ErrorDefault);
