import isArrayBuffer from 'is-array-buffer';
import { AccountApi, AuthenticationApi, Configuration, PasswordResettingApi, UserApi } from './openapi';
import globalAxios, { AxiosResponse } from 'axios';
import { routing } from './constants/routing';

export * from './openapi';

const configuration = new Configuration({
    basePath: process.env.REACT_APP_BACKEND_API,
    baseOptions: {
        withCredentials: true,
    },
});

export const authenticationApi = new AuthenticationApi(configuration);
export const accountApi = new AccountApi(configuration);
export const userApi = new UserApi(configuration);
export const resettingApi = new PasswordResettingApi(configuration);

const responseInterceptor = (response: AxiosResponse): AxiosResponse => {
    return response;
};
const errorInterceptor = async (error: any): Promise<any> => {
    const originalConfig = error.config;
    if (originalConfig.url.includes(routing.public.login) === false && originalConfig.url.includes(routing.public.tokenRefresh) === false && (Boolean(error.response))) {
        // Access Token was expired
        if (error.response.status === 401) {
            try {
                await authenticationApi.tokenRefresh();
                return await globalAxios(originalConfig);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }
    }

    if (error?.response?.data !== undefined) {
        if (isArrayBuffer(error.response.data)) {
            error.response.data = JSON.parse(new TextDecoder().decode(error.response.data));
        }
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
};
globalAxios.interceptors.response.use(responseInterceptor, errorInterceptor);
