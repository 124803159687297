import React, { useCallback } from 'react';
import { Error, translate } from '@wearephenix/phenix-front-core';
import ErrorIcon from '../../../resources/loupe.svg';
import { useNavigate } from 'react-router-dom';
import { routing } from '../../../helpers/constants/routing';

const ErrorLinkExpired = (): JSX.Element => {
    const navigate = useNavigate();
    const onClickResettingPassword = useCallback(() => {
        navigate(routing.public.resettingRequest);
    }, []);

    const onClickBack = useCallback(() => {
        navigate(routing.public.login);
    }, []);

    return <Error
        title={translate('label.resetting.link_expired.title')}
        icon={ErrorIcon}
        description={translate('label.resetting.link_expired.content')}
        button={{
            label: translate('login.resetting.button.ask_again'),
            onClick: onClickResettingPassword,
        }}
        link={{
            label: translate('label.resetting.button.back'),
            onClick: onClickBack,
        }}
    />;
};

export default React.memo(ErrorLinkExpired);
