import React, { useCallback, useState } from 'react';
import { translate, ViewPager } from '@wearephenix/phenix-front-core';
import UserFields from '../../organisms/UserFields';
import { changeUser, deleteUser, retrieveUser } from '../../../redux/actions/users';
import { routing } from '../../../helpers/constants/routing';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ApiLoader from '../../templates/ApiLoader';
import { UserUserDetails } from '../../../helpers/openapi';
import { Dispatcher } from '../../../redux/actionTypes';

export interface UserDetailsProps {
    type: 'create' | 'update'
}

const UserDetails = ({ type }: UserDetailsProps): JSX.Element => {
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch: Dispatcher = useDispatch();
    const [user, setUser] = useState<UserUserDetails | undefined>(undefined);

    const executeApiLoader = useCallback(async () => {
        if (type === 'update' && id !== undefined) {
            const userResult = await dispatch(retrieveUser(id));
            setUser(userResult);
        } else {
            setUser(undefined);
        }
    }, [type]);

    const onClickCreate = useCallback((firstname: string, lastname: string, email: string, phone: string, role: string, accounts: number[], emailNotification: boolean, canSign: boolean, canSignDac: boolean, canAdminTech: boolean) => {
        void (async () => {
            await dispatch(changeUser(type, id, firstname, lastname, email, phone, role, accounts, emailNotification, canSign, canSignDac, canAdminTech, navigate));
        })();
    }, [type]);

    const onClickLink = useCallback(() => {
        void (async () => {
            if (id !== undefined) {
                await dispatch(deleteUser(id));
                navigate(routing.admin.users);
            }
        })();
    }, [id]);

    let pageTitle = '';
    let buttonLabel = '';
    let linkLabel = '';

    switch (type) {
        case 'create':
            pageTitle = 'user.details.create.title';
            buttonLabel = 'user.details.create.button';
            break;
        case 'update':
            pageTitle = 'user.details.update.title';
            buttonLabel = 'user.details.update.button';
            linkLabel = 'user.details.delete.link';
            break;
    }

    return (
        <ApiLoader execute={executeApiLoader} localLoading={false}>
            <ViewPager tabs={[translate(pageTitle)]} fullscreen>
                <UserFields
                    buttonLabel={translate(buttonLabel)}
                    user={user}
                    onClickButton={onClickCreate}
                    linkLabel={translate(linkLabel)}
                    onClickLink={onClickLink}
                    type={type}
                />
            </ViewPager>
        </ApiLoader>
    );
};

export default React.memo(UserDetails);
