import React, { Dispatch, useCallback, useMemo, useState } from 'react';
import { usePropertyErrors, Button, InputWithLabel, TextInput, Section, Link, AlertSection, translate } from '@wearephenix/phenix-front-core';
import { Typography } from '@mui/material';
import loginLogo from '../../../../resources/login_logo.svg';
import { useDispatch } from 'react-redux';
import { requestResetting } from '../../../../redux/actions/main';
import styles from './RequestResetting.module.scss';
import { ResettingRequestParams } from '../../../../helpers/openapi';
import { useNavigate } from 'react-router-dom';
import { routing } from '../../../../helpers/constants/routing';

const RequestResetting = (): JSX.Element => {
    const dispatch: Dispatch<any> = useDispatch();
    const navigate = useNavigate();
    const [resettingRequestParams, setResettingRequestParams] = useState<ResettingRequestParams>({ email: '' });
    const propertyErrors = usePropertyErrors();
    const [step, setStep] = useState('request');

    const onClickResetting = useCallback(() => {
        void (async () => {
            try {
                await dispatch(requestResetting(resettingRequestParams));
                setStep('success');
            } catch (e) {
            }
        })();
    }, [resettingRequestParams]);

    const onEmailChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
        setResettingRequestParams({
            email: evt.target.value,
        });
    }, []);

    const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onClickResetting();
        }
    }, [onClickResetting]);

    const onClickBack = useCallback(() => {
        navigate(routing.public.login);
    }, []);

    const renderRequestForm = useMemo(() => {
        return <div className={styles.SectionContent}>
            <Typography variant={'body1'}>{translate('label.resetting.content')}</Typography>
            <div className={styles.InputContainer}>
                <InputWithLabel
                    forId={'email'}
                    label={translate('global.label.email')}
                >
                    <TextInput
                        id={'email'}
                        name={'email'}
                        hasError={propertyErrors.includes('email')}
                        value={resettingRequestParams.email}
                        onKeyDown={onKeyDown}
                        placeholder={translate('global.placeholder.email')}
                        onChange={onEmailChange}
                        dataCy={'requestResettingEmailInput'}
                    />
                </InputWithLabel>
            </div>
            <div className={styles.ButtonContainer}>
                <Button color={'coral'} label={translate('label.resetting.button.request')} onClick={onClickResetting} dataCy={'requestResettingButton'}/>
            </div>
        </div>;
    }, [propertyErrors, resettingRequestParams, onKeyDown, onEmailChange, onClickResetting]);

    const renderSuccessForm = useMemo(() => {
        return <div className={styles.SectionContent}>
            <Typography variant={'body2'}>{translate('label.resetting.success.subtitle', { email: resettingRequestParams.email })}</Typography>
            <div className={styles.TextContainer}>
                <Typography variant={'subtitle2'}>{translate('label.resetting.success.content')}</Typography>
            </div>
            <div className={styles.ButtonContainer}>
                <Link label={translate('label.resetting.button.back')} onClick={onClickBack} dataCy={'requestResettingBackLink'}/>
            </div>
        </div>;
    }, [resettingRequestParams, onClickBack]);

    return (
        <div className={styles.PageContainer}>
            <img src={loginLogo} className={styles.Logo} alt={'Phenix'}/>
            <div className={styles.AlertSectionContainer}>
                <AlertSection />
            </div>
            <div className={styles.SectionContainer}>
                <Section title={translate('label.resetting.title')} color={'black'}>
                    {step === 'success'
                        ? renderSuccessForm
                        : renderRequestForm
                    }
                </Section>
            </div>
        </div>
    );
};

export default React.memo(RequestResetting);
