import React, { useCallback } from 'react';
import { translate, Error, TokenData } from '@wearephenix/phenix-front-core';
import CornerIcon from '../../../resources/403_corner_icon.svg';
import { useNavigate } from 'react-router-dom';
import { routing } from '../../../helpers/constants/routing';
import { Dispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../redux/actions/globalInfos';
import { RootState } from '../../../redux/store';

export interface Error403Props {
    title?: string
    description?: string
    dataCy?: string
}

const Error403 = ({
    title = translate('error.403.title'),
    description = translate('error.403.content'),
    dataCy = 'error403',
}: Error403Props): JSX.Element => {
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const tokenData = useSelector<RootState, TokenData | null>(state => state.token.token);

    const onLogout = useCallback(() => {
        void (async () => {
            if (tokenData !== null) {
                await dispatch(logout());
            }
            navigate(routing.public.login);
        })();
    }, []);

    return <Error
        title={title}
        description={description}
        cornerIcon={CornerIcon}
        dataCy={dataCy}
        button={{
            label: translate('nav.global.logout'),
            onClick: onLogout,
        }}
    />;
};

export default React.memo(Error403);
