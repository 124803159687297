import { configureStore, Tuple } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import rootReducer from './reducers/';

export type RootState = ReturnType<typeof rootReducer>;

export default configureStore({
    reducer: rootReducer,
    middleware: () => new Tuple(thunk),
});
