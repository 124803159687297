import React, { Fragment, useEffect } from 'react';
import { GlobalLoading } from '@wearephenix/phenix-front-core';
import Routes from './helpers/Routes';
import styles from './App.module.scss';

function App(): JSX.Element {
    useEffect(() => {
        const link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
        let favicon = '/favicon.png';
        switch (process.env.REACT_APP_ENV) {
            case 'local':
                favicon = '/favicon-local.png';
                break;
            case 'qa':
                favicon = '/favicon-qa.png';
                break;
            case 'preprod':
                favicon = '/favicon-preprod.png';
                break;
            default:
                break;
        }
        if (link !== null) {
            link.href = favicon;
        } else {
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = favicon;
            document.head.appendChild(newLink);
        }
    }, []);

    return (
        <Fragment>
            <div className={styles.PageContainer}>
                <GlobalLoading />
                <Routes/>
            </div>
        </Fragment>
    );
}

export default App;
