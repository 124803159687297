import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from '../resources/translations/translations.json';
import { applyLanguage, i18n } from '@wearephenix/phenix-front-core';

void i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(languageDetector)
    .init({
        resources,
        compatibilityJSON: 'v3',
        fallbackLng: 'fr', // use en if detected lng is not available
        defaultNS: 'auth_front',
        react: {
            useSuspense: true,
        },
        // debug: true,
        interpolation: {
            escapeValue: false, // react already safes from xss
            prefix: '{',
            suffix: '}',
        },
    }, () => {
    });

applyLanguage();
