import React from 'react';
import { InputWithLabel, SwitchInput, TextInput, translate, usePropertyErrors } from '@wearephenix/phenix-front-core';
import AccountSearcher from '../AccountSearcher';
import { Grid, Typography } from '@mui/material';
import roles from '../../../helpers/constants/roles';
import styles from './AdditionalFields.module.scss';

export interface AdditionalFieldsProps {
    role: number
    phone: string
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    accountsLinked: any
    emailNotification: boolean
}

const AdditionalFields = ({
    role,
    phone,
    onInputChange,
    accountsLinked,
    emailNotification,
}: AdditionalFieldsProps): JSX.Element => {
    const propertyErrors = usePropertyErrors();
    const additionalFields = [];

    if (role !== roles.ADMIN.id) {
        additionalFields.push(
            <Grid item xs={12}>
                <InputWithLabel
                    forId={'phone'}
                    label={translate('user.field.label.phone')}
                >
                    <TextInput
                        id={'phone'}
                        name={'phone'}
                        value={phone}
                        hasError={propertyErrors.includes('phone')}
                        placeholder={translate('user.field.placeholder.phone')}
                        onChange={onInputChange}
                        dataCy={'authUserDetailsPhoneInput'}
                    />
                </InputWithLabel>
            </Grid>,
        );
    }

    if (role === roles.USER.id) {
        additionalFields.push(
            <React.Fragment key="additionalFields">
                <Grid item xs={12}>
                    <AccountSearcher id={'link'} accounts={accountsLinked.current} onChange={(values) => { accountsLinked.current = values; } }/>
                </Grid>
                <Grid item xs={12}>
                    <InputWithLabel
                        forId={'emailNotification'}
                        label={translate('label.user.field.email_notification')}
                    >
                        <div className={styles.SwitchContainer}>
                            <Typography variant='body1' className={styles.TextSwitch}>{translate('switch.user.field.email_notification')}</Typography>
                            <SwitchInput
                                id={'emailNotification'}
                                name={'emailNotification'}
                                checked={emailNotification}
                                onChange={onInputChange}
                                dataCy={'authUserFieldsEmailNotificationSwitch'}
                            />
                        </div>
                    </InputWithLabel>
                </Grid>
            </React.Fragment>,
        );
    }

    return <>{additionalFields}</>;
};

export default AdditionalFields;
