import React from 'react';
import { Button, Popup, translate } from '@wearephenix/phenix-front-core';

interface DeleteUserConfirmationPopupProps {
    onCancel: () => void
    onConfirm: () => void
    open: boolean
}

const DeleteUserConfirmationPopup = ({ onCancel, onConfirm, open = false }: DeleteUserConfirmationPopupProps): JSX.Element => {
    const footer = [
        <Button
            color="coral"
            onClick={onCancel}
            key={'deleteUserPopupCancelButton'}
            dataCy={'deleteUserPopupCancelButton'}
            label={translate('label.global.cancel')}
            variant={'outlined'}
        />,
        <Button
            color="coral"
            onClick={onConfirm}
            key={'deleteUserPopupConfirmButton'}
            dataCy={'deleteUserPopupConfirmButton'}
            label={translate('label.global.confirm')}
        />,
    ];

    return (
        <Popup
            open={open}
            onClose={() => {}}
            title={translate('popup.title.user.confirm_deletion')}
            hasCrossToCloseDialog={false}
            footer={footer}
            maxWidth={false}
            fullWidth={false}
            dataCy={'deleteUserPopup'}
        >
            <div>
                <p>{translate('popup.content.user.confirm_deletion')}</p>
            </div>
        </Popup>
    );
};

export default React.memo(DeleteUserConfirmationPopup);
