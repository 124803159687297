import React, { Dispatch, useCallback, useState } from 'react';
import { AlertSection, Button, InputWithLabel, Link, TextInput, TokenData, translate } from '@wearephenix/phenix-front-core';
import { Typography, Grid } from '@mui/material';
import loginLogo from '../../../resources/login_logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Model2faLoginCheckRequest } from '../../../helpers/openapi';
import styles from './TwoFA.module.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routing } from '../../../helpers/constants/routing';
import { RootState } from '../../../redux/store';
import useHasError from '../../../helpers/hooks/useHasError';
import { resendAuthCode, sendAuthCode } from '../../../redux/actions/main';

const TwoFA = (): JSX.Element | null => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch: Dispatch<any> = useDispatch();
    const tokenData = useSelector<RootState, TokenData | null>(state => state.token.token);
    const hasError = useHasError();
    const [authCode, setAuthCode] = useState<Model2faLoginCheckRequest>({ auth_code: '' });

    const redirect = useCallback(() => {
        const redirectParam = searchParams.get('redirect');
        if (redirectParam !== null) {
            window.location.href = redirectParam;
        } else {
            if (process.env.REACT_APP_URL_DESTOCK !== undefined) {
                window.location.href = process.env.REACT_APP_URL_DESTOCK;
            } else {
                navigate(routing.admin.users);
            }
        }
    }, [searchParams]);

    const onSubmit = useCallback(() => {
        void (async () => {
            await dispatch(sendAuthCode(authCode));
        })();
    }, [authCode]);

    const onInputChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
        setAuthCode({
            ...authCode,
            auth_code: evt.target.value,
        });
    }, [authCode]);

    const onKeyDown = useCallback((e: any) => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    }, [onSubmit]);

    if (tokenData !== null) {
        redirect();
    }

    const onClickResendAuthCode = useCallback(() => {
        void (async () => {
            await dispatch(resendAuthCode());
        })();
    }, []);

    return (
        <div className={styles.PageContainer}>
            <div className={styles.SectionContainer}>
                <img src={loginLogo} className={styles.Logo} alt={'Phenix'}/>
                <div className={styles.AlertSectionContainer}>
                    <AlertSection />
                </div>
                <Grid
                    container
                    alignContent={'center'}
                    justifyContent={'center'}
                >
                    <Grid item xs={12} sm={8} md={6}>
                        <Typography variant={'h1'} style={{ whiteSpace: 'pre-wrap' }}>{translate('two_fa.label.header')} </Typography>
                        <Typography variant={'body1'} style={{ whiteSpace: 'pre-wrap', marginBottom: '2rem' }}>{translate('two_fa.label.info')} </Typography>
                        <InputWithLabel
                            forId={'authCode'}
                            label={translate('two_fa.label.auth_code')}
                        >
                            <TextInput
                                id={'authCode'}
                                name={'authCode'}
                                hasError={hasError}
                                value={authCode.auth_code}
                                onKeyDown={onKeyDown}
                                placeholder={translate('two_fa.placeholder.auth_code')}
                                onChange={onInputChange}
                                dataCy={'twoFactorInput'}
                            />
                        </InputWithLabel>
                        <div className={styles.ButtonsContainer}>
                            <Button color={'coral'} label={translate('two_fa.button.submit')} onClick={onSubmit} dataCy={'twoFactorValidButton'}/>
                            <div className={styles.resendContainer}>
                                <Link label={translate('two_fa.button.resend_auth_code')} onClick={onClickResendAuthCode}/>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default React.memo(TwoFA);
