import React from 'react';
import styles from './Header.module.scss';
import { translate, NavItem } from '@wearephenix/phenix-front-core';
import { AppBar, Box, Container, Drawer, List, ListItem } from '@mui/material';
import { NavItems } from '../Nav';
import icon from '../../../resources/icon.svg';
// eslint-disable-next-line import/no-extraneous-dependencies
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import LogoutButton from '../../molecules/LogoutButton';

interface HeaderProps {
    window?: () => Window
}

const Header = ({ window }: HeaderProps): JSX.Element => {
    const userName = useSelector<RootState, string | null>(state => (state.token.token != null) ? `${state.token.token.firstname} ${state.token.token.lastname}` : null);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = (): void => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} className={styles.MobileNav}>
            <div className={styles.MobileNavIcon}>
                <img src={icon} alt="Phenix"/>
            </div>
            <List>
                { NavItems().map((item, key) => (
                    <ListItem key={key}>
                        <NavItem item={item} />
                    </ListItem>
                ))}
            </List>
            <LogoutButton/>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (<>
        <AppBar position="fixed" elevation={0} className={styles.Header} color={'transparent'}>
            <Container maxWidth={false}>
                <div
                    onClick={handleDrawerToggle}
                    color="inherit"
                    className={styles.BurgerMenu}
                >
                    <MenuIcon/>
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div/>
                    <div className={styles.UserInfoContainer}>
                        <label htmlFor={''}>{translate('label.global.user')}</label>
                        {userName}
                    </div>
                </Box>
            </Container>
        </AppBar>
        <div className={styles.HeaderPlacement}/>
        <Box component="nav">
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    </>
    );
};

export default Header;
