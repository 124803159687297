import React, { Dispatch, useCallback, useState } from 'react';
import {
    Button,
    InputWithLabel,
    TextInput,
    Section,
    Link,
    showMessage,
    AlertSection,
    translate,

    isServerException,
    isTranslatableException,
} from '@wearephenix/phenix-front-core';
import loginLogo from '../../../../resources/login_logo.svg';
import { useDispatch } from 'react-redux';
import { confirmResetToken, resetPassword } from '../../../../redux/actions/main';
import styles from './ResetPassword.module.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routing } from '../../../../helpers/constants/routing';
import ApiLoader from '../../../templates/ApiLoader';
import ErrorLinkExpired from '../../../molecules/ErrorLinkExpired';
import Error404 from '../../../molecules/Error404';
import ErrorDefault from '../../../molecules/ErrorDefault';
import useHasError from '../../../../helpers/hooks/useHasError';

const ResetPassword = (): JSX.Element => {
    const dispatch: Dispatch<any> = useDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [resetPasswordState, setResetPasswordState] = useState({ password: '', passwordConfirm: '' });
    const hasError = useHasError();

    const token = searchParams.get('token') ?? '';

    const onClickReset = useCallback(() => {
        void (async () => {
            try {
                await dispatch(resetPassword(token, resetPasswordState.password, resetPasswordState.passwordConfirm));
                dispatch(showMessage('success', translate('label.login.success.resetting')));
                navigate(routing.public.login);
            } catch (e) {
            }
        })();
    }, [resetPasswordState]);

    const onInputChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
        setResetPasswordState({
            ...resetPasswordState,
            [evt.target.id]: evt.target.value,
        });
    }, [resetPasswordState]);

    const onClickBack = useCallback(() => {
        navigate(routing.public.login);
    }, []);

    const executeApiLoader = useCallback(async () => {
        await dispatch(confirmResetToken(token));
    }, []);

    const screenError = useCallback((e: any) => {
        if (isTranslatableException(e) && e.error_message === 'reset.token.expired') {
            return <ErrorLinkExpired />;
        }
        if (isServerException(e) && e.status === 404) {
            return <Error404 />;
        }

        return <ErrorDefault exception={e}/>;
    }, []);

    return (
        <div className={styles.PageContainer}>
            <img src={loginLogo} className={styles.Logo} alt={'Phenix'}/>
            <div className={styles.AlertSectionContainer}>
                <AlertSection />
            </div>
            <div className={styles.SectionContainer}>
                <ApiLoader execute={executeApiLoader} screenError={screenError}>
                    <Section title={translate('label.resetting.title')} color={'black'}>
                        <div className={styles.SectionContent}>
                            <div className={styles.InputContainer}>
                                <div className={styles.PasswordContainer}>
                                    <InputWithLabel
                                        forId={'password'}
                                        label={translate('global.label.password')}
                                    >
                                        <TextInput
                                            id={'password'}
                                            name={'password'}
                                            value={resetPasswordState.password}
                                            hasError={hasError}
                                            placeholder={translate('global.placeholder.password')}
                                            onChange={onInputChange}
                                            minLength={8}
                                            minLengthMessage={translate('global.min.character', { count: '8' })}
                                            type={'password'}
                                            dataCy={'resetPasswordInput'}
                                        />
                                    </InputWithLabel>
                                </div>
                                <div className={styles.PasswordContainer}>
                                    <InputWithLabel
                                        forId={'passwordConfirm'}
                                        label={translate('global.label.password_confirm')}
                                    >
                                        <TextInput
                                            id={'passwordConfirm'}
                                            name={'passwordConfirm'}
                                            value={resetPasswordState.passwordConfirm}
                                            hasError={hasError}
                                            minLength={8}
                                            minLengthMessage={translate('global.min.character', { count: '8' })}
                                            placeholder={translate('global.placeholder.password')}
                                            onChange={onInputChange}
                                            type={'password'}
                                            dataCy={'resetPasswordConfirmInput'}
                                        />
                                    </InputWithLabel>
                                </div>
                            </div>
                            <div className={styles.ButtonContainer}>
                                <Button color={'coral'} label={translate('global.label.validate')} onClick={onClickReset} dataCy={'resetPasswordConfirmButton'}/>
                            </div>
                            <div className={styles.LinkContainer}>
                                <Link label={translate('label.resetting.button.back')} onClick={onClickBack}/>
                            </div>
                        </div>
                    </Section>
                </ApiLoader>
            </div>
        </div>
    );
};

export default React.memo(ResetPassword);
