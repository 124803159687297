import React, { useCallback } from 'react';
import styles from './LogoutButton.module.scss';
import { translate, NavItem } from '@wearephenix/phenix-front-core';
import classNames from 'classnames';
import navLogoutIcon from '../../../resources/nav_logout_icon.svg';
import { logout } from '../../../redux/actions/globalInfos';
import { Dispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

const LogoutButton = (): JSX.Element => {
    const dispatch: Dispatch<any> = useDispatch();
    const onLogout = useCallback(() => {
        void dispatch(logout());
    }, []);

    return (
        <div className={classNames(styles.LogoutContainer)} onClick={onLogout} data-cy={'authLogoutNavItem'}>
            <NavItem
                item={{
                    title: translate('nav.global.logout'),
                    link: onLogout,
                    icon: navLogoutIcon,
                }}
            />
        </div>
    );
};

export default React.memo(LogoutButton);
