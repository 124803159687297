export const routing = {
    admin: {
        users: '/users',
        createUser: '/user/create',
        userDetails: (id: number | string) => `/user/${id}`,
    },
    external: {
        phenixWebsite: 'https://www.wearephenix.com',
        phenixWebsiteContact: 'https://www.wearephenix.com/contact-pilier-contactez-nous',
    },
    public: {
        resetPassword: '/resetting/reset',
        resettingRequest: '/resetting/request',
        login: '/login',
        login_2fa: '/login/2fa',
        resend_2fa: '/login/2fa_resend',
        tokenRefresh: '/token/refresh',
        noAccess: '/no_access',
    },
};
