import React, { useCallback } from 'react';
import { AccountAccountDetails, accountApi } from '../../../helpers/api';
import { usePropertyErrors, AutocompleteInput, AutocompleteItem, InputWithLabel, translate } from '@wearephenix/phenix-front-core';
import styles from '../../organisms/UserFields/UserFields.module.scss';
import { AutocompleteGetTagProps, Typography } from '@mui/material';

export interface AccountSearcherProps {
    id: string
    accounts: AccountAccountDetails[]
    onChange: (selectedAccounts: AccountAccountDetails[]) => void
    isAdmin?: boolean
    disabled?: boolean
}
const AccountSearcher = ({ id, accounts, onChange, isAdmin = false, disabled = false }: AccountSearcherProps): JSX.Element => {
    const propertyErrors = usePropertyErrors();
    const lazyLoadAccount = useCallback(async (search: string) => {
        try {
            const result = await accountApi.getAccounts(search ?? '', accounts.map((value: any) => value.id));

            return result.data;
        } catch (e) {

        }
        return [];
    }, []);

    const renderTags = useCallback((tagValue: any[], getTagProps: AutocompleteGetTagProps) => {
        return tagValue.map((option: { type: string, name: string }, index: number) => {
            let color: 'coral' | 'green' | 'yellow' | 'pink' | 'blue' | 'dark_grey' = 'dark_grey';
            switch (option.type) {
                case 'client':
                    color = 'green';
                    break;
                case 'charity':
                    color = 'blue';
                    break;
            }
            // eslint-disable-next-line react/jsx-key
            return <AutocompleteItem color={color} label={option.name} {...getTagProps({ index })}/>;
        });
    }, []);

    return (
        <div className={styles.AutocompleteInput}>
            <InputWithLabel
                forId={id}
                label={isAdmin ? translate('user.field.label.admin_link') : translate('user.field.label.link')}
                required={!isAdmin}
            >
                { isAdmin && <Typography variant='subtitle2' style={{ fontStyle: 'italic' }}>{translate('user.field.info.account')}</Typography> }
                <AutocompleteInput
                    onChange={onChange}
                    options={[]}
                    filterOptions={(options: any) => options}
                    hasError={propertyErrors.includes('accounts')}
                    fetchData={lazyLoadAccount}
                    value={accounts}
                    placeholder={translate('user.field.placeholder.link')}
                    renderTags={renderTags}
                    dataCy={'authUserFieldsAccountInput'}
                    disablePortal={false}
                    disabled={disabled}
                />
            </InputWithLabel>
        </div>
    );
};

export default AccountSearcher;
