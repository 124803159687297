import React, { useCallback } from 'react';
import { translate, Error } from '@wearephenix/phenix-front-core';
import ErrorIcon from '../../../resources/loupe.svg';
import { routing } from '../../../helpers/constants/routing';
import { useNavigate } from 'react-router-dom';

const ErrorLinkExpired = (): JSX.Element => {
    const navigate = useNavigate();

    const onClickBack = useCallback(() => {
        navigate(routing.public.login);
    }, []);

    return <Error
        title={translate('error.404.title')}
        description={translate('error.404.content')}
        icon={ErrorIcon}
        button={{
            label: translate('error.button.back'),
            onClick: onClickBack,
        }}
    />;
};

export default React.memo(ErrorLinkExpired);
