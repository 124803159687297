import React, { useCallback, useMemo } from 'react';
import {
    Button,
    InputWithLabel,
    Section, SelectInput,
    TableContentLink,
    TableWrapper,
    ViewPager,
    translate, useSearch,
    SearchInput, SearchContainer, useChangeFilterAdapter, TokenDataPermissionsEnum, TableContentSmall,
} from '@wearephenix/phenix-front-core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routing } from '../../../helpers/constants/routing';
import ApiLoader from '../../templates/ApiLoader';
import {
    getUsers,
} from '../../../redux/actions/users';
import { UserUsersView } from '../../../helpers/openapi';
import { Dispatcher } from '../../../redux/actionTypes';
import { TableCell, TableRow } from '@mui/material';
import styles from './Users.module.scss';
import { RootState } from '../../../redux/reducers';
import AccountSearcher from '../../molecules/AccountSearcher';
import { usersList } from '../../../helpers/constants/search';
import EmptySection from '../../templates/EmptySection/EmptySection';
import EmptySectionIcon from '../../../resources/empty_hand_icon.svg';
import roles from '../../../helpers/constants/roles';

const Users = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch: Dispatcher = useDispatch();
    const { users, pageCountUsers } = useSelector((state: RootState) => {
        return state.users;
    });

    const userRoles = [
        {
            name: translate('users.role.all'),
            value: undefined,
            id: 0,
        },
        ...Object.keys(roles).map(key => {
            const role = roles[key as keyof typeof roles];
            return {
                name: role.name,
                value: role.value,
                id: role.id,
            };
        }),
    ];

    const searchRequest = useCallback(async (searchParams: { [key: string]: any }, page: number) => {
        const { searchText, filterByRole, filterByAccounts } = searchParams;
        await dispatch(getUsers(page, searchText, filterByRole, filterByAccounts));
    }, []);

    const defaultValue = useMemo(() => {
        return {
            searchText: '',
            filterByRole: undefined,
            filterByAccounts: [],
        };
    }, []);

    const [changePageHandler, currentPage, onChangeFilter, searchUsers, searchFields] = useSearch(usersList, searchRequest, defaultValue);

    const executeApiLoader = useCallback(async () => {
        await searchUsers(false);
    }, []);

    const filterByAccountsChangeHandler = useChangeFilterAdapter('filterByAccounts', onChangeFilter, useMemo(() => {
        return (value: any[]) => {
            return value;
        };
    }, []));

    const onClickCreateUser = useCallback(() => {
        navigate(routing.admin.createUser);
    }, []);

    return (
        <ApiLoader execute={executeApiLoader} localLoading={false}>
            <>
                <ViewPager tabs={[translate('users.title')]}>
                    <>
                        <div className={styles.ButtonContainer}>
                            <Button
                                label={translate('users.button.new')}
                                id={'UsersUserCreationButton'}
                                onClick={onClickCreateUser}
                                dataCy={'authUsersListUserCreationButton'}
                                color={'coral'}
                            />
                        </div>
                        <Section>
                            <SearchContainer
                                search={searchUsers}
                                dataCy={'authUsersListSearchButton'}
                            >
                                <InputWithLabel
                                    forId={'searchText'}
                                    label={translate('users.search.label')}>
                                    <SearchInput
                                        id={'searchText'}
                                        name={'searchText'}
                                        value={searchFields.searchText}
                                        placeholder={translate('users.search.placeholder')}
                                        onChange={onChangeFilter}
                                        dataCy={'authUsersListSearchInput'}
                                    />
                                </InputWithLabel>
                                <InputWithLabel
                                    forId={'filterByRole'}
                                    label={translate('users.role.label')}
                                    required={false}>
                                    <SelectInput id={'filterByRole'}
                                        value={searchFields.filterByRole}
                                        onChange={onChangeFilter}
                                        name={'filterByRole'}
                                        data={userRoles}
                                        optionValue={'value'}
                                        required={false}
                                        displayEmpty={true}
                                        fullWidth
                                        placeholder={translate('placeholder.users.role')}
                                        dataCy={'authUsersListRoleInput'}
                                    />
                                </InputWithLabel>
                                <AccountSearcher
                                    id={'filterByAccounts'}
                                    accounts={searchFields.filterByAccounts}
                                    onChange={filterByAccountsChangeHandler}
                                />
                            </SearchContainer>
                            <TableWrapper
                                fullWidth
                                name={'users-list'}
                                headers={[
                                    <>{translate('users.table.header.name')}</>,
                                    <>{translate('users.table.header.email')}</>,
                                    <>{translate('users.table.header.phone')}</>,
                                    <>{translate('users.table.header.activity')}</>,
                                    <>{translate('users.table.header.notifications')}</>,
                                    <>{translate('users.table.header.roles')}</>,
                                ]}
                                dataCy={'authUsersListTableWrapper'}
                                pagination={{
                                    count: pageCountUsers,
                                    currentPage,
                                    onChange: changePageHandler,
                                }}
                                isEmpty={users.length === 0}
                                emptySection={<EmptySection sectionWrapper={false} icon={EmptySectionIcon} text={translate('empty.users_list.text')} dataCy={'authUsersListTableEmpty'}/>}
                                content={
                                    users.map((user: UserUsersView) => {
                                        if (user.id === undefined) {
                                            return <TableRow key={`user-${Number(user.id)}`}></TableRow>;
                                        }

                                        return (
                                            <TableRow key={`user-${Number(user.id)}`}>
                                                <TableCell align={'left'}>
                                                    <TableContentLink to={routing.admin.userDetails(user.id)} label={user.name} color={'black'}/>
                                                    {/* To avoid tab/space added at the end of content on copy */}
                                                    <div></div>
                                                </TableCell>
                                                <TableCell align={'left'}>
                                                    {user.email}
                                                    {/* To avoid tab/space added at the end of content on copy */}
                                                    <div></div>
                                                </TableCell>
                                                <TableCell align={'left'}>
                                                    {(user.phone_number === '' || user.phone_number === undefined) ? translate('label.global.not_known') : user.phone_number}
                                                </TableCell>
                                                <TableCell align={'left'}>
                                                    {user.is_active === undefined || user.is_active === null ? translate('label.global.never') : (user.is_active ? translate('label.global.yes') : translate('label.global.no'))}
                                                </TableCell>
                                                <TableCell>
                                                    {user.email_notification ? translate('label.user.notifications.enabled') : translate('label.user.notifications.disabled')}
                                                </TableCell>
                                                <TableCell>
                                                    {user.roles.map((r: string) => translate(`label.global.roles.${r}`)).join(' ')}
                                                    {user.permissions?.includes(TokenDataPermissionsEnum.Signatory) ? <TableContentSmall label={translate('label.user.field.can_sign')}/> : null}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            />
                        </Section>
                    </>
                </ViewPager>
            </>
        </ApiLoader>
    );
};

export default React.memo(Users);
