/* tslint:disable */
/* eslint-disable */
/**
 * Phenix Auth Documentation
 * Swagger
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DefaultResponse } from '../models';
// @ts-ignore
import { NotFoundException } from '../models';
// @ts-ignore
import { ResetPasswordParams } from '../models';
// @ts-ignore
import { ResettingRequestParams } from '../models';
// @ts-ignore
import { ValidationException } from '../models';
/**
 * PasswordResettingApi - axios parameter creator
 * @export
 */
export const PasswordResettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} resetToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmResetToken: async (resetToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetToken' is not null or undefined
            assertParamExists('confirmResetToken', 'resetToken', resetToken)
            const localVarPath = `/public/resetting/confirm/{resetToken}`
                .replace(`{${"resetToken"}}`, encodeURIComponent(String(resetToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResettingRequestParams} [resettingRequestParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequest: async (resettingRequestParams?: ResettingRequestParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/resetting/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resettingRequestParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordParams} [resetPasswordParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReset: async (resetPasswordParams?: ResetPasswordParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/resetting/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordResettingApi - functional programming interface
 * @export
 */
export const PasswordResettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordResettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} resetToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmResetToken(resetToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmResetToken(resetToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResettingRequestParams} [resettingRequestParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequest(resettingRequestParams?: ResettingRequestParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequest(resettingRequestParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPasswordParams} [resetPasswordParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReset(resetPasswordParams?: ResetPasswordParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReset(resetPasswordParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordResettingApi - factory interface
 * @export
 */
export const PasswordResettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordResettingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} resetToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmResetToken(resetToken: string, options?: any): AxiosPromise<DefaultResponse> {
            return localVarFp.confirmResetToken(resetToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResettingRequestParams} [resettingRequestParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequest(resettingRequestParams?: ResettingRequestParams, options?: any): AxiosPromise<DefaultResponse> {
            return localVarFp.postRequest(resettingRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordParams} [resetPasswordParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReset(resetPasswordParams?: ResetPasswordParams, options?: any): AxiosPromise<DefaultResponse> {
            return localVarFp.postReset(resetPasswordParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordResettingApi - object-oriented interface
 * @export
 * @class PasswordResettingApi
 * @extends {BaseAPI}
 */
export class PasswordResettingApi extends BaseAPI {
    /**
     * 
     * @param {string} resetToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResettingApi
     */
    public confirmResetToken(resetToken: string, options?: AxiosRequestConfig) {
        return PasswordResettingApiFp(this.configuration).confirmResetToken(resetToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResettingRequestParams} [resettingRequestParams] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResettingApi
     */
    public postRequest(resettingRequestParams?: ResettingRequestParams, options?: AxiosRequestConfig) {
        return PasswordResettingApiFp(this.configuration).postRequest(resettingRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordParams} [resetPasswordParams] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResettingApi
     */
    public postReset(resetPasswordParams?: ResetPasswordParams, options?: AxiosRequestConfig) {
        return PasswordResettingApiFp(this.configuration).postReset(resetPasswordParams, options).then((request) => request(this.axios, this.basePath));
    }
}
