
import { v4 } from 'uuid';

const initialState: MainState = {
    uuid: v4(),
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state: MainState = initialState): MainState => {
    return state;
};

export default reducer;
