import React from 'react';
import './helpers/translator';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemePhenix } from '@wearephenix/phenix-front-core';
import { Provider } from 'react-redux';

import store from './redux/store';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
const theme = createTheme(ThemePhenix);

root.render(
    <StyledEngineProvider injectFirst={true}>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </StyledEngineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
