import { combineReducers } from '@reduxjs/toolkit';
import users from './users';
import main from './main';
import {
    AlertSectionReducer,
    ErrorsReducer,
    LoadingReducer,
    SearchReducer,
    TokenReducer,
} from '@wearephenix/phenix-front-core';

const rootReducer = combineReducers({
    main,
    search: SearchReducer,
    loading: LoadingReducer,
    errors: ErrorsReducer,
    token: TokenReducer,
    alertSection: AlertSectionReducer,
    users,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
