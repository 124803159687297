import React from 'react';
import styles from './Nav.module.scss';
import icon from '../../../resources/icon.svg';
import icon_blue from '../../../resources/phenix_icon_blue.svg';
import icon_yellow from '../../../resources/phenix_icon_yellow.svg';
import icon_green from '../../../resources/phenix_icon_green.svg';
import navUserIcon from '../../../resources/nav_user_icon.svg';
import navDestockIcon from '../../../resources/nav_destock_icon.svg';
import { translate, NavItem, NavItemObject } from '@wearephenix/phenix-front-core';
import LogoutButton from '../../molecules/LogoutButton';
import { List, ListItem } from '@mui/material';
import { routing } from '../../../helpers/constants/routing';

export const NavItems = (): NavItemObject[] => {
    return [
        {
            icon: navUserIcon,
            title: translate('nav.admin.users'),
            link: routing.admin.users,
        },
        {
            icon: navDestockIcon,
            title: translate('nav.admin.destock'),
            link: process.env.REACT_APP_URL_DESTOCK ?? '',
        },
    ];
};
const Nav = (): JSX.Element => {
    let phenixIcon = icon;
    switch (process.env.REACT_APP_ENV) {
        case 'local':
            phenixIcon = icon_blue;
            break;
        case 'qa':
            phenixIcon = icon_green;
            break;
        case 'preprod':
            phenixIcon = icon_yellow;
            break;
        default:
            break;
    }
    return (
        <nav className={styles.Nav}>
            <div className={styles.FixedNavContainer} data-cy={'authFixedNavContainer'}>
                <div className={styles.FixedNav}>
                    <div className={styles.NavIcon}>
                        <img src={phenixIcon} alt="Phenix"/>
                    </div>
                    <List>
                        { NavItems().map((item, key) => (
                            <ListItem key={key}>
                                <NavItem item={item}/>
                            </ListItem>
                        )) }
                    </List>
                    <LogoutButton/>
                </div>
            </div>
        </nav>
    );
};

export default Nav;
