import React from 'react';
import styles from './EmptySection.module.scss';
import { Section } from '@wearephenix/phenix-front-core';
import { Typography } from '@mui/material';

interface EmptySectionProps {
    icon: string
    text: string
    dataCy?: string
    sectionWrapper?: boolean
}

const EmptySection = ({ icon, text, sectionWrapper = true, dataCy = undefined }: EmptySectionProps): JSX.Element => {
    const emptySectionContent = (): JSX.Element => {
        return (
            <div className={styles.EmptySectionContainer} data-cy={dataCy}>
                <img className={styles.EmptySectionIcon} src={icon} alt={'Phenix'}/>
                <Typography variant="body1" className={styles.EmptySectionText}>{text}</Typography>
            </div>
        );
    };
    return (sectionWrapper
        ? (
            <Section>
                {emptySectionContent()}
            </Section>
        )
        : emptySectionContent());
};

export default React.memo(EmptySection);
