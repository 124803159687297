import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ErrorPage, Loading, translate } from '@wearephenix/phenix-front-core';
import Users from '../components/pages/Users';
import UserDetails from '../components/pages/UserDetails';
import RequestResetting from '../components/pages/resetting/RequestResetting';
import ResetPassword from '../components/pages/resetting/ResetPassword';
import Authenticated from '../components/templates/security/Authenticated';
import LoadTokenData from '../components/templates/security/LoadTokenData';
import TwoFA from '../components/pages/TwoFA';
import { routing } from './constants/routing';
import Error403 from '../components/molecules/Error403';

const Login = lazy(async () => import('../components/pages/Login'));

const routes = (): JSX.Element => {
    return (
        <>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path={routing.public.noAccess} element={<ErrorPage><Error403 description={translate('error.403.carrier.desc')} dataCy={'error403Carrier'}/></ErrorPage>}/>
                    <Route path={routing.public.resetPassword} element={<ResetPassword/>}/>
                    <Route path={routing.public.resettingRequest} element={<RequestResetting/>}/>
                    <Route element={<LoadTokenData/>}>
                        <Route path={routing.public.login} element={<Login/>}/>
                        <Route path={routing.public.login_2fa} element={<TwoFA/>}/>
                        <Route element={<Authenticated/>}>
                            <Route path={routing.admin.users} element={<Users/>}/>
                            <Route path={routing.admin.createUser} element={<UserDetails type={'create'}/>}/>
                            <Route path={routing.admin.userDetails(':id')} element={<UserDetails type={'update'}/>}/>
                        </Route>
                    </Route>
                    <Route
                        path="*"
                        element={<Navigate to={routing.public.login} replace />}
                    />
                </Routes>
            </Suspense>
        </>
    );
};

export default routes;
