/* tslint:disable */
/* eslint-disable */
/**
 * Phenix Auth Documentation
 * Swagger
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountAccountSearch } from '../models';
// @ts-ignore
import { DefaultResponse } from '../models';
// @ts-ignore
import { PutAccountParams } from '../models';
// @ts-ignore
import { UserAccountEmail } from '../models';
// @ts-ignore
import { ValidationException } from '../models';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {Array<any>} [excluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: async (search?: string, excluded?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (excluded) {
                localVarQueryParameter['excluded[]'] = excluded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<any>} [sfIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsEmails: async (sfIds?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sfIds) {
                localVarQueryParameter['sfIds[]'] = sfIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutAccountParams} [putAccountParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAccount: async (putAccountParams?: PutAccountParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putAccountParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {Array<any>} [excluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccounts(search?: string, excluded?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccountSearch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccounts(search, excluded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<any>} [sfIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsEmails(sfIds?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAccountEmail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsEmails(sfIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PutAccountParams} [putAccountParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAccount(putAccountParams?: PutAccountParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAccount(putAccountParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {Array<any>} [excluded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(search?: string, excluded?: Array<any>, options?: any): AxiosPromise<Array<AccountAccountSearch>> {
            return localVarFp.getAccounts(search, excluded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<any>} [sfIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsEmails(sfIds?: Array<any>, options?: any): AxiosPromise<Array<UserAccountEmail>> {
            return localVarFp.getAccountsEmails(sfIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PutAccountParams} [putAccountParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAccount(putAccountParams?: PutAccountParams, options?: any): AxiosPromise<DefaultResponse> {
            return localVarFp.putAccount(putAccountParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {string} [search] 
     * @param {Array<any>} [excluded] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccounts(search?: string, excluded?: Array<any>, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccounts(search, excluded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<any>} [sfIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccountsEmails(sfIds?: Array<any>, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccountsEmails(sfIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PutAccountParams} [putAccountParams] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public putAccount(putAccountParams?: PutAccountParams, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).putAccount(putAccountParams, options).then((request) => request(this.axios, this.basePath));
    }
}
