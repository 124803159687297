import { AccountAccountDetails, userApi, UserUsersView } from '../../helpers/api';
import { hideError, loading, showError } from '@wearephenix/phenix-front-core';
import { Dispatch } from '@reduxjs/toolkit';
import { UsersAction, UsersDispatch } from '../types/users';
import {
    UPDATE_USERS_DATA,
} from '../actionTypes';
import { routing } from '../../helpers/constants/routing';
import { NavigateFunction } from 'react-router-dom';

export function changeUser(type: 'create' | 'update', id: string | undefined, firstname: string, lastname: string, email: string, phone: string, role: string, accounts: number[], emailNotification: boolean, canSign: boolean, canSignDac: boolean, canAdminTech: boolean, navigate: NavigateFunction) {
    return async (dispatch: any) => {
        try {
            dispatch(hideError());
            dispatch(loading(true));

            const params = {
                email,
                firstname,
                lastname,
                phone: phone.length === 0 ? undefined : phone,
                role,
                accounts,
                email_notification: emailNotification,
                can_sign: canSign,
                can_sign_dac: canSignDac,
                can_admin_tech: canAdminTech,
            };

            switch (type) {
                case 'create':
                    await userApi.putUser(params);
                    break;
                case 'update':
                    if (id === undefined) {
                        console.error('id should not be undefined');
                    } else {
                        await userApi.postUserAdmin(id, params);
                    }
                    break;
            }

            navigate(routing.admin.users);
        } catch (e) {
            dispatch(showError(e, 'error.validation.user_creation.title', 'error.validation.property.'));
            // throw e;
        } finally {
            dispatch(loading(false));
        }
    };
}

function updateUsersData(users: UserUsersView[], pageCount: number) {
    return (dispatch: UsersDispatch) => {
        const action: UsersAction = {
            type: UPDATE_USERS_DATA,
            params: {
                users,
                pageCount,
            },
        };

        dispatch(action);
    };
}

export function getUsers(page: number, searchText: string, filterByRole: string | undefined, filterByAccounts: AccountAccountDetails[]) {
    return async (dispatch: Dispatch<any>) => {
        try {
            const accountIds: number [] = filterByAccounts.map((a: AccountAccountDetails) => a.id);
            dispatch(loading(true));
            const { data } = await userApi.getUsers(String(page), searchText, filterByRole, accountIds);
            dispatch(updateUsersData(data.users, data.page_count));
            return data;
        } finally {
            dispatch(loading(false));
        }
    };
}

export function retrieveUser(id: string) {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch(loading(true));
            const result = await userApi.getUser(id);
            return result.data;
        } finally {
            dispatch(loading(false));
        }
    };
}

export function deleteUser(id: string) {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch(loading(true));
            await userApi.deleteUser(id);
        } finally {
            dispatch(loading(false));
        }
    };
}
