import {
    authenticationApi,
    LoginRequest, Model2faLoginCheckRequest,
    resettingApi,
    ResettingRequestParams,
} from '../../helpers/api';
import { retrieveToken } from './globalInfos';
import { hideError, loading, showError, showMessage, translate } from '@wearephenix/phenix-front-core';
import { NavigateFunction } from 'react-router-dom';
import { routing } from '../../helpers/constants/routing';

export function login(loginInfos: LoginRequest, navigate: NavigateFunction, redirectParam: URLSearchParams) {
    return async (dispatch: any) => {
        try {
            dispatch(hideError());
            dispatch(loading(true));
            const { data } = await authenticationApi.login(loginInfos);

            if ('two_factor_auth' in data && !data.two_factor_auth) {
                navigate({ pathname: routing.public.login_2fa, search: `?${redirectParam.toString()}` });
            } else {
                await dispatch(retrieveToken());
            }
        } catch (e) {
            dispatch(showError(e));
            throw e;
        } finally {
            dispatch(loading(false));
        }
    };
}
export function sendAuthCode(authCode: Model2faLoginCheckRequest) {
    return async (dispatch: any) => {
        try {
            dispatch(hideError());
            dispatch(loading(true));
            await authenticationApi._2faLoginCheck(authCode);
            await dispatch(retrieveToken());
        } catch (e) {
            dispatch(showError(e));
        } finally {
            dispatch(loading(false));
        }
    };
}

export function requestResetting(resettingRequestParams: ResettingRequestParams) {
    return async (dispatch: any) => {
        try {
            dispatch(hideError());
            dispatch(loading(true));
            await resettingApi.postRequest(resettingRequestParams);
        } catch (e) {
            dispatch(showError(e));
            throw e;
        } finally {
            dispatch(loading(false));
        }
    };
}

export function confirmResetToken(resetToken: string) {
    return async () => {
        await resettingApi.confirmResetToken(resetToken);
    };
}

export function resetPassword(token: string, password: string, passwordConfirm: string) {
    return async (dispatch: any) => {
        try {
            if (password.length < 8) {
                throw new Error(translate('error.password.length'));
            }

            if (passwordConfirm !== password) {
                throw new Error(translate('error.password_confirm.not_same'));
            }
            dispatch(hideError());
            dispatch(loading(true));
            await resettingApi.postReset({
                reset_token: token,
                password,
            });
        } catch (e) {
            dispatch(showError(e));
            throw e;
        } finally {
            dispatch(loading(false));
        }
    };
}

export function resendAuthCode() {
    return async (dispatch: any) => {
        try {
            dispatch(hideError());
            dispatch(loading(true));
            await authenticationApi.getResendAuthCode();
            dispatch(showMessage('success', translate('two_fa.local.resend_success')));
        } catch (e) {
            dispatch(showError(e));
            throw e;
        } finally {
            dispatch(loading(false));
        }
    };
}
