/* tslint:disable */
/* eslint-disable */
/**
 * Phenix Auth Documentation
 * Swagger
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DefaultResponse } from '../models';
// @ts-ignore
import { Login200Response } from '../models';
// @ts-ignore
import { LoginException } from '../models';
// @ts-ignore
import { LoginRequest } from '../models';
// @ts-ignore
import { LoginResponse } from '../models';
// @ts-ignore
import { Model2faLoginCheckRequest } from '../models';
// @ts-ignore
import { NotFoundException } from '../models';
// @ts-ignore
import { SwitchParams } from '../models';
// @ts-ignore
import { SwitchResponse } from '../models';
// @ts-ignore
import { TokenDataUser } from '../models';
// @ts-ignore
import { TokenRefreshRequest } from '../models';
// @ts-ignore
import { TranslatableException } from '../models';
// @ts-ignore
import { ValidationException } from '../models';
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check 2fa auth code
         * @param {Model2faLoginCheckRequest} model2faLoginCheckRequest user credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _2faLoginCheck: async (model2faLoginCheckRequest: Model2faLoginCheckRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'model2faLoginCheckRequest' is not null or undefined
            assertParamExists('_2faLoginCheck', 'model2faLoginCheckRequest', model2faLoginCheckRequest)
            const localVarPath = `/login/2fa_check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(model2faLoginCheckRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResendAuthCode: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/2fa_resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get jwt
         * @param {LoginRequest} loginRequest user credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SwitchParams} [switchParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchAccount: async (switchParams?: SwitchParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/switch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(switchParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refresh jwt
         * @param {TokenRefreshRequest} [tokenRefreshRequest] refresh token with cookie or params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenRefresh: async (tokenRefreshRequest?: TokenRefreshRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRefreshRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check 2fa auth code
         * @param {Model2faLoginCheckRequest} model2faLoginCheckRequest user credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _2faLoginCheck(model2faLoginCheckRequest: Model2faLoginCheckRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Login200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._2faLoginCheck(model2faLoginCheckRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResendAuthCode(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResendAuthCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenDataUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get jwt
         * @param {LoginRequest} loginRequest user credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Login200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SwitchParams} [switchParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async switchAccount(switchParams?: SwitchParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SwitchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.switchAccount(switchParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary refresh jwt
         * @param {TokenRefreshRequest} [tokenRefreshRequest] refresh token with cookie or params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenRefresh(tokenRefreshRequest?: TokenRefreshRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenRefresh(tokenRefreshRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Check 2fa auth code
         * @param {Model2faLoginCheckRequest} model2faLoginCheckRequest user credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _2faLoginCheck(model2faLoginCheckRequest: Model2faLoginCheckRequest, options?: any): AxiosPromise<Login200Response> {
            return localVarFp._2faLoginCheck(model2faLoginCheckRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResendAuthCode(options?: any): AxiosPromise<DefaultResponse> {
            return localVarFp.getResendAuthCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken(options?: any): AxiosPromise<TokenDataUser> {
            return localVarFp.getToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get jwt
         * @param {LoginRequest} loginRequest user credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<Login200Response> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogout(options?: any): AxiosPromise<DefaultResponse> {
            return localVarFp.postLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SwitchParams} [switchParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchAccount(switchParams?: SwitchParams, options?: any): AxiosPromise<SwitchResponse> {
            return localVarFp.switchAccount(switchParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary refresh jwt
         * @param {TokenRefreshRequest} [tokenRefreshRequest] refresh token with cookie or params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenRefresh(tokenRefreshRequest?: TokenRefreshRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.tokenRefresh(tokenRefreshRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Check 2fa auth code
     * @param {Model2faLoginCheckRequest} model2faLoginCheckRequest user credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public _2faLoginCheck(model2faLoginCheckRequest: Model2faLoginCheckRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration)._2faLoginCheck(model2faLoginCheckRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getResendAuthCode(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getResendAuthCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getToken(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get jwt
     * @param {LoginRequest} loginRequest user credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public login(loginRequest: LoginRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public postLogout(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).postLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SwitchParams} [switchParams] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public switchAccount(switchParams?: SwitchParams, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).switchAccount(switchParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary refresh jwt
     * @param {TokenRefreshRequest} [tokenRefreshRequest] refresh token with cookie or params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public tokenRefresh(tokenRefreshRequest?: TokenRefreshRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).tokenRefresh(tokenRefreshRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
